$(document).foundation();
Foundation.Abide.defaults.patterns['fr_tel'] = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
Foundation.Abide.defaults.patterns['fr_time'] = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
// implementation of disabled form fields
var nowTemp = new Date();
var tomorrow = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate()+1, 0, 0, 0, 0);
var onemonth = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate()+60, 0, 0, 0, 0);
var checkin = $('#dpd1').fdatepicker({
    language: 'fr-FR',
    onRender: function (date) {
        return date.valueOf() < tomorrow.valueOf() || date.valueOf() > onemonth.valueOf() ? 'disabled' : '';
    }
});
var checkin2 = $('#dpd2').fdatepicker({
    language: 'fr-FR',
    onRender: function (date) {
        return date.valueOf() < tomorrow.valueOf() || date.valueOf() > onemonth.valueOf() ? 'disabled' : '';
    }
});
var checkin3 = $('#dpt').fdatepicker({
        format: 'dd-mm-yyyy hh:ii',
        language: 'fr-FR',
        pickTime: true,
        onRender: function (date) {
        return date.valueOf() < tomorrow.valueOf() || date.valueOf() > onemonth.valueOf() ? 'disabled' : '';
    }
    });
var checkin4 = $('#heureDepartR').fdatepicker({
        format: 'dd-mm-yyyy hh:ii',
        language: 'fr-FR',
        pickTime: true,
        onRender: function (date) {
        return date.valueOf() < tomorrow.valueOf() || date.valueOf() > onemonth.valueOf() ? 'disabled' : '';
    }
    });
function affiche_bloc(CheckBox) {
        if (CheckBox.checked)
        {
            document.getElementById("infos").style.display="block";
        }
        else
        {
            document.getElementById("infos").style.display="none";
        }
}
function retire_bloc(CheckBox) {
        if (CheckBox.checked)
        {
            document.getElementById("infos").style.display="none";
        }
        else
        {
            document.getElementById("infos").style.display="block";
        }
}